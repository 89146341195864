import { useQuery} from '@apollo/client';
import { Queries } from "./common_queries";

export const People_Email = (email_id) => {
    return useQuery(Queries.People_Email,{
        variables: {email_id: email_id}
    });
}

export const GetPropertyBooking = (id) => {
    return useQuery(Queries.GetPropertyBooking,{
        variables: {id: id}
    });
}

export const GetOffPlanPropertyBooking = (id) => {
    return useQuery(Queries.GetOffPlanPropertyForBooking,{
        variables: {id: id}
    });
}

export const NoResultProperties = (status, department, search_type) => {
    return useQuery(Queries.NoResultProperties, {
        variables: {status: status, department: department, search_type:search_type}
    });
}

export const NoResultOffplanProperties = (status, department, search_type) => {
    return useQuery(Queries.NoResultOffplanProperties, {
        variables: {status: status, department: department, search_type:search_type}
    });
}