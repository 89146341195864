import gql from "graphql-tag"

const People_Email = gql`
query GetPeopleEmail($email_id: String!){
    teams(where:{email:$email_id}){
        name
        email
        mobile_no
        designation
        slug
        image {
            url
            alternativeText
        }
    }
}`

/* ========== Property Detail For Booking ======== */

const GetPropertyBooking = gql`
query GetPropertyForBooking($id: ID!) {
    property (id: $id) {
        id
        slug
        address
        display_address
        search_type
        department
        title
        images
        bedroom
        currency
        imagetransforms
        bathroom
        office_crm_id
        price
        negotiator
        negotiator_id
  }
}`

/* ========== Off plan Property Detail For Booking ======== */

const GetOffPlanPropertyForBooking = gql`
query GetOffPlanPropertyForBooking($id: ID!) {
    offplan (id: $id) {
        id
        slug
        address
        display_address
        search_type
        department
        title
        images
        ggfx_results {
            id
            content_type
            transforms
            src_import_url
            src_cftle
            field
        }
        imagetransforms
        bedroom
        bathroom
        office_crm_id
        price
  }
}`

/* ========== No Results Properties ======== */
const NoResultProperties = gql`
query NoResultProperties($status: String!, $department: String!, $search_type: String!){
    properties(limit:6, where:{status:$status, department:$department, search_type:$search_type ,publish:true}) {
        id
        search_type
        status
        department
        price
        price_qualifier
        currency
        display_address
        area
        bedroom
        bathroom
        reception
        imagetransforms
        building
        slug
        title
        images
        crm_negotiator_id
    }
}`

/* ========== No Results offplan Properties ======== */
const NoResultOffplanProperties = gql`
query NoResultProperties($status: String!, $department: String!, $search_type: String!){
    offplans(limit:6, where:{status:$status, department:$department, search_type:$search_type ,publish:true}) {
        id
        search_type
        status
        department
        price
        price_qualifier
        display_address
        area
        bedroom
        bathroom
        reception
        imagetransforms
        building
        slug
        title
        images
        crm_negotiator_id
    }
}`

export const Queries = {
    People_Email,
    GetPropertyBooking,
    NoResultProperties,
    NoResultOffplanProperties,
    GetOffPlanPropertyForBooking
};